import { smsPeriod } from '@/const'
import { post } from '@/axios'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export default {
  name: 'sms-mixin',
  data() {
    return {
      sendingSms: false,
    }
  },
  computed: {
    allowGetSms() {
      return this.$store.state.sms.remainSecond === smsPeriod
    },
    smsBtnText() {
      return this.allowGetSms
        ? '获取验证码'
        : this.$store.state.sms.remainSecond
    },
  },
  methods: {
    setSmsCreateTime() {
      this.$store.commit('setSmsCreateTime')
    },
    handleCountdown() {
      if (
        this.$store.state.sms.remainSecond <= 0 ||
        this.$store.state.sms.finishTime === null
      ) {
        this.$store.commit('resetSms')
        return
      }

      const remainSecond = dayjs(this.$store.state.sms.finishTime).diff(
        dayjs(),
        'second',
      )
      if (this.$store.state.sms.finishTime !== null) {
        this.$store.commit('setSmsRemainSecond', remainSecond)
      }
    },
    async sendSmsCode({ area_prefix, user_phone, phone_flag }) {
      if (!user_phone) {
        return this.$message.error('请输入手机号码', 2)
      }
      const mapStr = JSON.stringify({
        area_prefix,
        user_phone,
        phone_flag,
      })

      try {
        const { code, info } = await post(
          'sendPhoneCodeSms.do',
          this.Qs.stringify({ mapStr }),
        )

        if (code === 'fail') {
          this.$message.error('发送失败' /*info*/, 2)
        } else {
          this.setSmsCreateTime()
        }
      } catch {
      }
    },
  },
  watch: {
    '$store.state.sms': {
      immediate: true,
      deep: true,
      handler(sms, prevSms) {
        if (
          sms.createTime === null ||
          sms.finishTime === null ||
          sms.timer !== null
        ) {
          return
        }
        if (sms.remainSecond < 1) {
          this.$store.commit('resetSms')
          return
        }

        this.handleCountdown()

        const smsTimer = setInterval(() => {
          this.handleCountdown()
        }, 1000)

        this.$store.commit('setSmsTimer', smsTimer)
      },
    },
  },
}
