<template>
  <div class="setting-phone-view">
    <div class="form">
      <div class="form-input flex-ac">
        <div class="form-label flex-ac">手机号码</div>
        <div class="code-selector">
          <a-select default-value="+86" @change="handleSelectAreaCode">
            <a-select-option
              v-for="area in $store.state.areaCodes"
              :key="area.init_value"
              :value="area.init_value"
            >
              {{ area.init_value }}
            </a-select-option>
          </a-select>
        </div>
        <input v-model="form.user_phone" placeholder="请输入" type="text" />
      </div>
      <div class="form-input flex-ac">
        <div class="form-label flex-ac">短信验证码</div>
        <input v-model="form.vcode" placeholder="请输入" type="text" />
        <div
          :class="[
            'sms-btn btn',
            {
              active: $store.state.sms.remainSecond === smsPeriod,
              sending: this.sendingSms,
              counting: !this.allowGetSms,
            },
          ]"
          @click="sendSms"
          v-text="smsBtnText"
        ></div>
      </div>
      <!-- <div>{{ $store.state.sms.remainSecond }}</div>
      <div @click="setCreateTime" style="cursor: pointer">START</div>
      <div @click="$store.commit('resetSms')" style="cursor: pointer">
        RESET
      </div> -->
    </div>
    <div :class="['submit btn', { active: !submitting }]" @click="submit">
      提&nbsp;交
    </div>
  </div>
</template>

<script>
import { smsPeriod } from '@/const'
import sms from '@/mixins/sms'
import { post } from '@/axios'

export default {
  name: 'setting-phone',
  mixins: [sms],
  data() {
    return {
      loading: true,
      submitting: false,
      smsPeriod,
      form: {
        vcode: '',
        area_prefix: '+86',
        user_phone: '',
        phone_flag: 'updatePhone_'
      }
    }
  },
  async created() {
    try {
      await this.$store.dispatch('getAreaCodes')
    } catch {
    } finally {
      this.loading = false
    }
  },
  methods: {
    async handleSelectAreaCode(e) {
      this.form.area_prefix = e
    },
    async sendSms() {
      if (!this.allowGetSms) return

      this.sendingSms = true
      try {
        const { area_prefix, user_phone, phone_flag } = this.form
        await this.sendSmsCode({
          area_prefix,
          user_phone, // '17158820683',
          phone_flag
        })
      } catch {
      } finally {
        this.sendingSms = false
      }
    },
    async submit() {
      if (this.submitting) return
      if (!this.form.user_phone) {
        return this.$message.error('请输入手机号码', 2)
      }
      if (!this.form.vcode) {
        return this.$message.error('请输入短信验证码', 2)
      }

      try {
        this.submitting = true
        const { vcode, area_prefix, user_phone, phone_flag } = this.form

        const mapStr = JSON.stringify({
          vcode,
          area_prefix,
          user_phone,
          phone_flag
        })
        const { code, data, info } = await post(
          'updateMemberPhone.do',
          this.Qs.stringify({ mapStr })
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { msg, flag } = JSON.parse(data)
          if (flag === '1') {
            this.$message.error(msg, 2)
          } else {
            this.$message.success('修改成功', 2)
          }
        }
      } catch {
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.setting-phone-view {
  flex-direction: column;

  .form {
    // width: 100%;
    width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.9rem;

    .form-input {
      width: 100%;
      height: 50px;
      margin-bottom: 15px;
      border-bottom: 1px solid #777;
      color: white;
      position: relative;

      .form-label {
        width: 6rem;
        user-select: none;
      }

      input {
        background: transparent;
        box-shadow: none;
        border: none;
        width: 12rem;
        height: 100%;
        color: white;
      }

      .sms-btn {
        padding: 0;
        width: 6rem;
        height: 1.5rem;
        border-radius: 5px;
        font-size: 0.7rem;
        position: absolute;
        right: 1rem;
        font-weight: normal;
        letter-spacing: 1px;

        &.sending {
          cursor: wait;
        }

        &.counting {
          cursor: not-allowed;
        }
      }
    }
  }

  .submit {
    margin-top: 1rem;
    margin-left: 24rem;
  }

  @include md {
    .form {
      width: 100%;

      .form-input {
        .form-label {
          font-size: 18px;
        }

        input {
          font-size: 16px;
        }
      }
    }

    .submit {
      margin: 1rem auto 0;
    }
  }
}
</style>
